import React, { useState } from "react"
import { navigate } from "gatsby"
import queryString from 'query-string'

const Introduction = (props) => {
  const params = queryString.parse(props.location.search);
  const enqueteId = params.id;
  const userId = params.userId;

  const openEnquete = () => {
    console.log(enqueteId, 'enqueteId');
    console.log(userId, 'userId');
    navigate(
      `/?id=${enqueteId}&userId=${userId}`,
      { replace: false }
    )
  }

  return (
    <div className="container">
      <section className="section">
        <div className="container">
          <h2 className="title">「平城宮跡歴史公園」および「平城宮跡歴史公園スマートチャレンジ実証実験」の利用に関するアンケート</h2>

          <p>平城宮跡歴史公園をご利用いただき、誠にありがとうございました。<br/>
            平城宮跡歴史公園スマートチャレンジNTT西日本事務局では、当公園とGPSを利用したサービスをより便利で快適なものとするため、利用者の皆様にアンケート調査を実施しています。<br/>
            ご協力のほど、よろしくお願いいたします。<br/>
            <br/>
            実施主体：平城宮跡歴史公園スマートチャレンジNTT西日本事務局<br/>
            <br/>
            ※回答時間：7分程度（全15問）<br/>
            <br/>
          </p>
          <p>
            ≪本アンケートに関する問い合わせ・連絡先≫<br/>
            NTT西日本事務局<br/>
            電話番号 0120-214-046<br/>
            受付時間 9：00〜17：00(平日のみ)
          </p>
        </div>
      </section>

      <section className="section is-paddingless">
        <div className="container">
          <div className="buttons is-centered has-margin-bottom-30">
            <button className="button is-medium is-danger is-outlined" onClick={openEnquete}>アンケートに進む</button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Introduction